<template>
  <div class="menuTemplate">
    <section>
      <div v-if="featuredScreen">
        <featuredParent :featuredScreen="featuredScreen"  :screenName="menu.id"></featuredParent>
      </div>
      <div v-for="(item, index) in categoryScreens" :key="index">
        <continueSlider
          v-if="
            (item.sectionType === 'CONTINUEWATCH' || item.sectionType === 'CONTINUEWATCHING') && (item.status == 'ACTIVE' || item.isSectionEnabled)
          "
          :screen="item"
          :playerInstance="playerInstance"
        ></continueSlider>
        <slider
          v-if="
            (item.sectionType !== 'CONTINUEWATCH' && item.sectionType !== 'CONTINUEWATCHING')  &&
              (item.isSectionEnabled || item.status == 'ACTIVE') &&
              (!item.userTypes ||
                (item.userTypes && item.userTypes.includes('ALL')) ||
                (item.userTypes && item.userTypes.includes(getUserType())))
          "
          :screen="item"
          :id="index"
          :playerInstance="playerInstance"
          :screenName="menu.id"
        ></slider>
      </div>
    
    </section>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
export default {
  props: {
    screens: {
      type: [Object, Array],
    },
    menu: {
      type: [Object, String],
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      enableTemplate: false,
      templates: [],
      featuredScreen: "",
      categoryScreens: [],
      localDisplayLang: null,
      showHoveredColor: false,
      subscriptions: [],   
    };
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "getToken",
      "getRtl",
      "subscriptionList",
      "getFirebaseUserUID",
      "appConfig"
    ]),
  },
  watch: {
    subscriberid(val) {
      if (val) {
        this.renderTemplate();
      }
    },
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'mr' ? 'mar' : this.localDisplayLang;

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });

    eventBus.$on("rerender-template", () => {
      //fetching screens of that particular menu.
      this.renderTemplate();
      //toggling the menu filter component.
      this.renderMenuFilter();
    });

    //fetching screens of that particular menu.
    this.renderTemplate();

    //toggling the menu filter component.
    this.renderMenuFilter();
  },
  mounted() {
    // this.loadContinueFireData();

    setTimeout(() => {
      if (
        this.localDisplayLang === "ara" &&
        this.continueWatchData &&
        this.continueWatchData.length < 5
      ) {
        // console.log(
        //   "continue carousel",
        //   document.getElementById("continueWatch-carousel-iw")
        // );
        document.getElementById("continueWatch-carousel-iw")
          ? (document.getElementById(
              "continueWatch-carousel-iw"
            ).style.textAlign = "-webkit-right")
          : "";
      }
    }, 700);
  },
  methods: {
  
    getUserType() {
      if (!this.subscriberid) {
        return "GUEST";
      } else if (this.subscriberid && this.subscriptions.length > 0) {
        return "SUBSCRIBED";
      } else {
        return "REGISTERED";
      }
    },
    renderTemplateUsingDecking () {
      this.featuredScreen = this.screens.sections[
        this.screens.sections.findIndex((element) => {
          return element.sectionType == "FILTER" && element.listType == "CAROUSEL";
        })
      ];
      this.categoryScreens = this.screens.sections.filter((element) => {
        if ((element.sectionType == "FILTER" && element.listType == "LIST") || (element.itemType == "CONTENT" && element.sectionType == "ITEMLIST")  || (element.sectionType == "CONTINUEWATCHING")) {
          return element;
        }
      });
    },
    renderTemplate() {
      if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
          this.renderTemplateUsingDecking();
          return;
      }

      this.featuredScreen = this.screens.sections[
        this.screens.sections.findIndex((element) => {
          return element.sectionType === "FEATURED";
        })
      ];
      this.categoryScreens = this.screens.sections.filter((element) => {
        return element.sectionType !== "FEATURED";
      });
   
    },
    //toggling the menu filter component based on the hasFilter property in each menu object.
    renderMenuFilter() {
      let payload = {};
      if (!this.menu) {
        payload.state = false;
      } else if (!this.menu.hasOwnProperty("hasFilter")) {
        payload.state = false;
      } else if (this.menu.hasOwnProperty("hasFilter") && this.menu.hasFilter) {
        payload.state = true;
        payload.menu = this.menu;
      } else if (this.menu === null) {
        payload.state = false;
      } else {
        payload.state = false;
      }
      eventBus.$emit("toggleMenuFilter", payload);
      window.scrollTo(0, 0);
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },

    hoverEffect() {
      this.showHoveredColor = true;
    },

    noHoverEffect() {
      this.showHoveredColor = false;
    },
  },

  beforeDestroy() {
    eventBus.$off("rerender-template");
  },

  components: {
    featuredParent: () =>
      import(
        /* webpackChunkName: "featured" */ "@/components/Carousels/featuredParent.vue"
      ),
    slider: () =>
      import(
        /* webpackChunkName: "carousel" */ "@/components/categorySlider/slider.vue"
      ),
    continueSlider: () =>
      import(
        /* webpackChunkName: "contslider" */ "@/components/categorySlider/continueSlider.vue"
      ),
  },
  // mixins: [FirebaseActions]
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./menuTemplate.scss"
</style>
